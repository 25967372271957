import React from 'react';
import Main from './src/layouts/main';
import Map from './src/components/map';

export const wrapPageElement = ({ element, props }) => {
  return (
    <Main {...props} map={<Map {...props} />}>
      {element}
    </Main>
  );
};

// TODO: review this
export const onRouteUpdate = ({ location }) => {
  const page = document.querySelector('.content');
  page.scrollTop = 0;
};
