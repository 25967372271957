const geojsonbbox = require('geojson-bbox');

const getBboxFromCoordinates = function(coordinates) {
  const bbox = [180, 90, -180, -90];

  if (!coordinates.every(c => Array.isArray(c)))
    throw 'Corrdinates must be an array of arrays';

  const bounds = coordinates.reduce(
    (prev, curr) => [
      Math.min(curr[0], prev[0]),
      Math.min(curr[1], prev[1]),
      Math.max(curr[0], prev[2]),
      Math.max(curr[1], prev[3])
    ],
    bbox
  );

  return bounds;
};

const getBboxFromFeatures = function(features) {
  const collection = {
    type: 'FeatureCollection',
    features: features.map(f => ({ type: 'Feature', geometry: f }))
  };

  return geojsonbbox(collection);
};

const formatBbox = function(bbox) {
  return [[bbox[0], bbox[1]], [bbox[2], bbox[3]]];
};

module.exports = { getBboxFromCoordinates, getBboxFromFeatures, formatBbox };
