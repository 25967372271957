import React, { Component } from 'react';
import ReactMapboxGl, {
  Layer,
  Feature,
  Popup,
  GeoJSONLayer,
  Marker,
  ZoomControl,
  Source
} from 'react-mapbox-gl';

import { getBboxFromCoordinates, formatBbox } from '../geo';

import { navigate, Link } from 'gatsby';

import User from '../img/User.svg';
import Pin from '../img/Pin.svg';
import AreaPin from '../img/Area-Pin.svg';
import RoutePin from '../img/Route-Pin.svg';
import StepPin1 from '../img/Step-Pin-1.svg';
import StepPin2 from '../img/Step-Pin-2.svg';
import StepPin3 from '../img/Step-Pin-3.svg';
import StepPin4 from '../img/Step-Pin-4.svg';
import StepPin5 from '../img/Step-Pin-5.svg';
import StepPin6 from '../img/Step-Pin-6.svg';
import StepPin7 from '../img/Step-Pin-7.svg';
import FuzzyPin1 from '../img/Fuzzy-Pin-1.svg';
import FuzzyPin2 from '../img/Fuzzy-Pin-2.svg';
import FuzzyPin3 from '../img/Fuzzy-Pin-3.svg';

const PinImage = new Image();
PinImage.src = Pin;
const PinMarker = ['Pin', PinImage];

const AreaImage = new Image();
AreaImage.src = AreaPin;
const AreaMarker = ['Area', AreaImage];

const RouteImage = new Image();
RouteImage.src = RoutePin;
const RouteMarker = ['Route', RouteImage];

const defaultLayout = { 'icon-anchor': 'bottom' };

const PinLayout = { ...defaultLayout, 'icon-image': 'Pin' };
const AreaLayout = { ...defaultLayout, 'icon-image': 'Area' };
const RouteLayout = { ...defaultLayout, 'icon-image': 'Route' };

const LayerMarkers = {
  point: { layout: PinLayout, images: PinMarker },
  area: { layout: AreaLayout, images: AreaMarker },
  route: { layout: RouteLayout, images: RouteMarker }
};

const RouteMarkers = {
  1: StepPin1,
  2: StepPin2,
  3: StepPin3,
  4: StepPin4,
  5: StepPin5,
  6: StepPin6,
  7: StepPin7
};

const FuzzyMarkers = {
  1: FuzzyPin1,
  2: FuzzyPin2,
  3: FuzzyPin3
};

const MapGL = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiYmVydGV6IiwiYSI6ImNqdmNsbGZkZzAwZ2Y0NG11azE2bm15NnAifQ.sAWr793GMP7wama3vDRqpw',
  attributionControl: false
});

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      popup: null,
      user: null,
      focusUser: false,
      showFeatures: true,
      showHelp: false,
      fitted: true
    };

    this.userMove = this.userMove.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.focusUser = this.focusUser.bind(this);
    this.focusFeatures = this.focusFeatures.bind(this);
    this.toggleFeatures = this.toggleFeatures.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.pageContext.map.bbox !== newProps.pageContext.map.bbox) {
      this.setState({ fitted: true });
    }
  }

  userMove() {
    this.setState({ popup: null, fitted: false });
  }

  watchLocation() {
    this.watcher = navigator.geolocation.watchPosition(
      this.updateLocation,
      function(error) {
        console.error(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }

  updateLocation(position) {
    const { coords } = position;

    const { longitude, latitude } = coords;

    this.setState({
      user: [longitude, latitude]
    });
  }

  focusUser() {
    if (!this.watcher) this.watchLocation();
    this.setState({
      focusUser: true
    });
  }

  focusFeatures() {
    this.setState({
      focusUser: false,
      fitted: true
    });
  }

  toggleFeatures() {
    this.setState({
      showFeatures: !this.state.showFeatures
    });
  }

  toggleHelp() {
    this.setState({
      showHelp: !this.state.showHelp
    });
  }

  buildLayer(type) {
    if (!this.props.pageContext.map.features) return;
    //Points
    const features = this.props.pageContext.map.features.filter(
      f => f.node.frontmatter.type === type
    );

    const MapFeatures = features.map((f, i) => (
      <Feature
        key={`${type}_${i}`}
        coordinates={[...f.node.fields.centroid.coordinates]}
        onClick={() =>
          f.node.frontmatter.visible && this.setState({ popup: f.node })
        }
      />
    ));

    const marker = LayerMarkers[type];

    return (
      <Layer type="symbol" layout={marker.layout} images={marker.images}>
        {MapFeatures}
      </Layer>
    );
  }

  buildRoute() {
    if (!this.props.pageContext.map.features) return;

    const Markers = this.props.pageContext.map.features.map((f, i) => {
      return (
        <Marker
          key={`step_${i}`}
          anchor="bottom"
          coordinates={[...f.node.fields.centroid.coordinates]}
          onClick={() =>
            f.node.frontmatter.visible && this.setState({ popup: f.node })
          }
        >
          <img src={RouteMarkers[i + 1]} alt="Parada" />
        </Marker>
      );
    });

    return <>{Markers}</>;
  }

  buildBoxes() {
    if (!this.props.pageContext.map.box) return;

    const Boxes = this.props.pageContext.map.box.map((b, i) => {
      return (
        <GeoJSONLayer
          key={`box_${i}`}
          data={b.geo}
          fillPaint={{ 'fill-color': 'rgb(45, 156, 219)', 'fill-opacity': 0.5 }}
          fillOnClick={() => b.slug && navigate(b.slug)}
        />
      );
    });

    return <>{Boxes}</>;
  }

  buildLines() {
    if (!this.props.pageContext.map.line) return;

    const Lines = this.props.pageContext.map.line.map((l, i) => {
      return (
        <GeoJSONLayer
          key={`line_${i}`}
          data={l.geo}
          linePaint={{
            'line-color': 'rgb(45, 156, 219)',
            'line-width': 5,
            'line-opacity': 0.5
          }}
        />
      );
    });

    return <>{Lines}</>;
  }

  buildMapList() {
    const Markers = this.props.pageContext.map.features.map((f, i) => {
      return (
        <Marker
          key={`map_${i}`}
          anchor="bottom"
          coordinates={[...f.node.fields.centroid.coordinates]}
          onClick={() => this.setState({ popup: f.node })}
        >
          <img src={FuzzyMarkers[i + 1]} alt="Parada" />
        </Marker>
      );
    });

    return <>{Markers}</>;
  }

  buildSources() {
    return (
      <>
        <Source
          id="overlay"
          tileJsonSource={{
            type: 'raster',
            url: `mapbox://${this.props.pageContext.map.tileset}`,
            tileSize: 256
          }}
        />
        <Layer
          type="raster"
          sourceId="overlay"
          paint={{ 'raster-opacity': 0.6 }}
        />
      </>
    );
  }

  render() {
    const { popup, user, loaded, showFeatures, showHelp, fitted } = this.state;
    const { bbox, route, maps, tileset } = this.props.pageContext.map;

    return (
      <section className="map">
        <MapGL
          style="mapbox://styles/mapbox/light-v9"
          fitBounds={
            this.state.focusUser && this.state.user
              ? formatBbox(getBboxFromCoordinates([this.state.user]))
              : fitted
              ? bbox
              : null
          }
          fitBoundsOptions={{ padding: 50, maxZoom: 16 }}
          onMove={this.userMove}
          onStyleLoad={() => this.setState({ loaded: true })}
          containerStyle={{
            height: '100%',
            width: '100%'
          }}
        >
          <ZoomControl position="bottom-right" className="control" />
          {showFeatures && this.buildLines()}
          {showFeatures && this.buildBoxes()}
          {showFeatures
            ? route
              ? this.buildRoute()
              : this.buildLayer('point')
            : null}
          {showFeatures && this.buildLayer('area')}
          {showFeatures && this.buildLayer('route')}
          {showFeatures && maps ? this.buildMapList() : null}
          {showFeatures && tileset ? this.buildSources() : null}

          {showFeatures && popup ? (
            <Popup
              key={3}
              coordinates={popup.fields.centroid.coordinates}
              offset={{ bottom: [0, -52] }}
              className="pt-popup"
            >
              <Link to={popup.fields.slug}>{popup.frontmatter.title}</Link>
            </Popup>
          ) : null}

          {user ? (
            <Marker coordinates={this.state.user}>
              <img src={User} alt="Localización usuario/a" />
            </Marker>
          ) : null}
        </MapGL>
        {loaded ? (
          <div className="buttons">
            <button className="help" onClick={this.toggleHelp}>
              ?
            </button>
            <button
              className={`${showFeatures ? 'features' : 'nofeatures'}`}
              onClick={this.toggleFeatures}
            >
              F
            </button>
            <button className="user" onClick={this.focusUser}>
              U
            </button>
            <button className="home" onClick={this.focusFeatures}>
              H
            </button>
          </div>
        ) : null}

        {showHelp ? (
          <div className="buttons-help">
            <p>
              <span>Mostrar/ocultar elementos</span>
            </p>
            <p>
              <span>Centrar en usuario/a</span>
            </p>
            <p>
              <span>Centrar en elementos</span>
            </p>
            <p>
              <span>Acercar zoom</span>
            </p>
            <p>
              <span>Alonxar zoom</span>
            </p>
          </div>
        ) : null}
      </section>
    );
  }
}

export default Map;
