import React from 'react';
import { Link } from 'gatsby';

const InfoList = ({
  info,
  showIntro = false,
  showFuzzyCount = false,
  className = ''
}) => {
  return (
    <ul className={`info-list ${className}`}>
      {info.map((item, i) => (
        <li key={`infoList_${i}`}>
          {showFuzzyCount ? <div className="count">{i + 1}</div> : null}
          <div className="info">
            <Link to={item.node.fields.slug}>
              {item.node.frontmatter.title}
            </Link>
            {showIntro ? <p>{item.node.frontmatter.intro}</p> : null}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default InfoList;
