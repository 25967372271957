import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import InfoList from '../components/infoList';

import '../css/base.css';

const Main = ({ children, map }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/info/" }
          frontmatter: { hidden: { eq: true } }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  return (
    <main className="main-layout">
      <section className="content">
        {children}

        <footer>
          <p>Proxectoterra, 2019</p>
          <InfoList info={data.allMarkdownRemark.edges} className="footer" />
        </footer>
      </section>
      {map ? map : null}
    </main>
  );
};

export default Main;
